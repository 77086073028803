<template>
  <MiscellaneousBidModifier dialog-name="Bid Modifiers" />
</template>
<script>
import MiscellaneousBidModifier from '@/views/app/miscellaneous/BidModifier'

export default {
  name: 'BidModifierWrapper',
  components: {
    MiscellaneousBidModifier
  }
}
</script>
